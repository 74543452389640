import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import config from '../../config';


import css from './Logo.module.css';

const Logo = props => {
  const { className, format, isLandingPage, ...rest } = props;
  const mobileClasses = classNames(css.logoMobile, className);

  // If you want to use svg instead of image as a logo you can use the following code.
  // Also, remember to import the svg as IconLogo here.
  // <IconLogo className={format === 'desktop' ? className : mobileClasses} format={format} {...rest} />

  return format === 'desktop' ? (
    <span className={classNames(css.logo, className, {
      [css.logoLandingPage]: isLandingPage,
    })}>
      {config.siteTitle}
      </span>
  ) : (
    <span className={mobileClasses}>
      {config.siteTitle}
    </span>
  );
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
